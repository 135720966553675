import { useParams } from 'react-router-dom';
import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { Spinner } from 'components/common/Spinner';
import { Map3DCanvas } from 'shared/map-3d/map-3d-canvas/Map3DCanvas';
import { useAisleView } from 'shared/map-3d/aisle-view/hooks/useAisleView';
import { AisleView } from 'shared/map-3d/aisle-view/AisleView';
import MuiBox from '@mui/material/Box';
import {
  Card,
  Divider,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { AisleSummary } from 'shared/map-3d/aisle-summary/AisleSummary';
import { ErrorBoundary } from 'react-error-boundary';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Group } from 'three';
import { ZoomControls } from 'shared/map-3d/zoom-controls/ZoomControls';
import { useZoomToggle } from 'shared/map-3d/hooks/useZoomToggle';
import { findNodesByType } from 'shared/map-3d/map-node.util';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { ILocationDataST } from 'codegen/warehouse_status';
import { LocationDetailsDrawer } from 'shared/map-3d/location-details-drawer/LocationDetailsDrawer';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { useLocationDetails } from 'shared/map-3d/hooks/useLocationDetails';
import { useActiveBinSelection } from 'shared/map-3d/hooks/useActiveBinSelection';
import { BinHighlight } from 'shared/map-3d/bin-highlight/BinHighlight';
import { AisleViewScene } from '../../../../shared/map-3d/aisle-view/features/AisleViewScene';
import { TopViewMap } from './features/top-view-map/TopViewMap';
import { useLocationData } from '../warehouse-status/hooks/useLocationData';
import { useWarehouseStatus3DStyle } from './styles/warehouseStatus3D.style';
import { TopViewScene } from './features/top-view-map/features/top-view-scene/TopViewScene';
import { HitTargetNodeST } from './features/top-view-map/TopViewMap.model';

export const WarehouseStatus3D = () => {
  const { classes } = useWarehouseStatus3DStyle();
  const { systemId = '' } = useParams<{ systemId: string }>();
  const { isLoadingLocations, locationDataMap, locationData } = useLocationData(systemId);
  const { data: facilityMap, isLoading } = useFacilityMap(systemId ?? '');

  const [currentFlightDomain, setCurrentFlightDomain] = useState(facilityMap?.nodes?.[0]?.name);

  const [selectedAisle, setSelectedAisle] = useState<HitTargetNodeST>();
  const { aisleBins, summary = [] } = useAisleView({
    mapNode: selectedAisle ?? facilityMap?.nodes?.find((node) => node.name === currentFlightDomain),
    locationDataMap,
    currentLocationName: locationData[0] ? locationData[0].slot_label : '',
  });

  const [view, setView] = useState<'top' | 'aisle'>('top');
  const topViewRef = useRef<Group>(null);
  const aisleRef = useRef<Group>(null);
  const handleViewToggle = useCallback(
    (_: React.MouseEvent<HTMLElement>, value: 'aisle' | 'top') => {
      setView((previousView) => (!value ? previousView : value));
    },
    [],
  );

  const { activeBinName, aisleBinsWithHandlers, resetActiveBin } = useActiveBinSelection(aisleBins);

  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;
  const location = locationDataMap.get(activeBinName);

  const selected3dBin = useMemo(
    () => aisleBins.find((bin) => bin.name === activeBinName),
    [activeBinName, aisleBins],
  );

  const issueLogic = useMemo(() => {
    if (!location) {
      return undefined;
    }
    const selectedLocation = getRowForFullReportTable(
      location?.slot_label ?? '',
      location,
      location?.issues ?? [],
    );

    const { issueLogic } = location
      ? getIssueAndBarcodeMatchingLogic(
          selectedLocation as unknown as ILocationDataST,
          facilitySettings,
        )
      : { issueLogic: undefined };
    return issueLogic;
  }, [location, facilitySettings]);

  const details = useLocationDetails({
    activeBinName,
    issueLogic,
    locationDataMap,
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const { zoomType, handleZoomChange, setZoomType } = useZoomToggle({ type: 'width' });

  const handleTabChange = useCallback(
    (_: PointerEvent, newValue: string) => {
      setCurrentFlightDomain(newValue);
      const currentFlightDomain = facilityMap?.nodes?.find((node) => node.name === newValue);
      if (currentFlightDomain) {
        const flightDomainAisleSides = findNodesByType(currentFlightDomain, 'AISLE_SIDE');
        setSelectedAisle(flightDomainAisleSides[0] as HitTargetNodeST);
      }
    },
    [facilityMap?.nodes],
  );

  const handleSelected = useCallback(
    (selected: HitTargetNodeST) => {
      setSelectedAisle(selected);
      setView('aisle');
      setZoomType({ type: 'width' });
    },
    [setZoomType],
  );

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageHeader
          systemId={systemId}
          title="Warehouse map"
          subtitle="See surrounding issues and navigate to other locations"
        />
      </PageLayout.Header>

      <PageLayout.Section>
        <div className={classes.pageWrapper}>
          {isLoading || isLoadingLocations ? (
            <Spinner />
          ) : (
            <Card className={classes.card}>
              <MuiBox>
                <Tabs
                  value={currentFlightDomain ?? facilityMap?.nodes?.[0]?.name}
                  onChange={handleTabChange}
                >
                  {facilityMap?.nodes?.map(({ name }) => (
                    <Tab key={name} label={name} value={name} />
                  ))}
                </Tabs>
                <Divider className={classes.divider} />
              </MuiBox>
              <ErrorBoundary
                fallback={
                  <Typography
                    color="textSecondary"
                    textAlign="center"
                    variant="h6"
                    component="p"
                    gutterBottom
                  >
                    Sorry an error occured loading the map
                  </Typography>
                }
              >
                <MuiBox className={classes.wrapper} ref={containerRef}>
                  <ToggleButtonGroup
                    value={view}
                    onChange={handleViewToggle}
                    aria-label="View"
                    exclusive
                    className={classes.viewToggle}
                  >
                    <ToggleButton value="aisle">Aisle</ToggleButton>
                    <ToggleButton value="top">Top</ToggleButton>
                  </ToggleButtonGroup>
                  <Map3DCanvas className={classes.canvas}>
                    {view === 'aisle' ? (
                      <AisleView ref={aisleRef} bins={aisleBinsWithHandlers} />
                    ) : null}
                    {selected3dBin?.position && selected3dBin?.normal ? (
                      <BinHighlight
                        normal={selected3dBin.normal}
                        position={selected3dBin.position}
                        scale={selected3dBin.scale}
                      />
                    ) : null}
                    {view === 'top' ? (
                      <TopViewMap
                        selectedFlightDomain={
                          currentFlightDomain ?? facilityMap?.nodes?.[0]?.name ?? ''
                        }
                        ref={topViewRef}
                        handleSelected={handleSelected}
                        selectedAisleId={selectedAisle?.id}
                      />
                    ) : null}
                    {view === 'aisle' ? (
                      <AisleViewScene
                        aisleRef={aisleRef}
                        normal={selectedAisle?.normal}
                        position={selectedAisle?.position}
                        fit={zoomType}
                      />
                    ) : (
                      <TopViewScene
                        selectedFlightDomain={
                          currentFlightDomain ?? facilityMap?.nodes?.[0]?.name ?? ''
                        }
                        topViewRef={topViewRef}
                      />
                    )}
                  </Map3DCanvas>
                  <AisleSummary className={classes.aisleSummary} issues={summary} />
                  {view === 'aisle' ? (
                    <>
                      <ZoomControls className={classes.zoomControls} onZoom={handleZoomChange} />
                      <LocationDetailsDrawer
                        open={!!activeBinName && !!selected3dBin}
                        containerRef={containerRef}
                        onClose={resetActiveBin}
                        locationName={activeBinName}
                        details={details}
                      />
                    </>
                  ) : null}
                </MuiBox>
              </ErrorBoundary>
            </Card>
          )}
        </div>
      </PageLayout.Section>
    </PageLayout>
  );
};
