import { useCallback, useState, useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { TIMEFRAME_TO_FETCH_REPORTS_FROM } from 'common/settings';
import { getTimeIntervalDates } from 'common/functions/dateTimeFunctions';
import { MapContainer } from 'shared/map-container/MapContainer';
import { getEstimatedObstacles } from 'shared/map-container/utils/getEstimatedObstacles';
import { webSocket } from 'shared/web-socket/web-socket';
import { EnhancedTable } from 'components/EnhancedTable';
import { IObstacleInformation1ST } from 'codegen/flight_domain';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { SelectTimeInterval } from 'components/Page/select-time-interval/SelectTimeInterval';
import { CardGrid } from 'components/common/CardGrid/CardGrid';
import { transformTasksToTableRows } from './transform/transformTasksToTableRows';
import { useFetchCommissioningTasks } from './api/useFetchCommissioningTasks';
import { CommissioningTask } from './model/commissioningTask.model';
import {
  commissioningTaskStateFromTab,
  CommissioningTaskTab,
} from './utils/commissioningTaskStateFromTab';
import { filterCommissioningTasks } from './utils/filtering/filterCommissioningTasks';
import { AbortCommissioningTasksModal } from './features/AbortCommissioningTasksModal';
import {
  EstimatedObstaclesMap,
  wrapperId,
} from './features/EstimatedObstaclesMap/EstimatedObstaclesMap';
import { initialFlightDomain } from '../../../store/GroundControl/groundControlInitialState';
import {
  IFlightDomainData,
  GroundControlActionNames,
} from '../../../store/GroundControl/groundControlLevelStore.model';
import { useGroundControlStore } from '../../../store/GroundControl/groundControlLevelStore';
import { useRequestController } from '../../../hooks';
import {
  CommissioningPageSubscribersOptions,
  commissioningPageSubscribers,
} from './web-socket/commissioning-page.subscribers';
import { subscribeToEstimatedObstacles } from './web-socket/subscribe-to-estimated-obstacles';
import { CommissioningTaskTableRow } from './model/commissioningTaskTableRow.model';
import { tabDataCommissioning } from './defaults/tabDataCommissioning.defaults';
import { commissioningTableHeaders } from './defaults/commissioningTableHeaders.defaults';
import { FilterDatesCommissioning } from './model/commissioningDateFilter.model';

const logPrefix = getLogPrefixForType('PAGE', 'Commissioning');

/**
 * The commissioning page
 * @param props may receive the EstimatedObstaclesMap
 * @returns JSX.Element
 */
export const CommissioningPage = () => {
  console.debug(logPrefix, 'render');

  const location = useLocation();
  const [searchParams] = useSearchParams();

  type LoadSince = Extract<'all' | 'last week' | 'last two weeks' | 'last month', string | null>;
  const loadSince = searchParams.get('loadSince') as LoadSince;
  const currentTabIndex = Number(searchParams.get('activeTab'));

  const dates = getTimeIntervalDates(loadSince || 'last week') as FilterDatesCommissioning;
  const [filterDates, setFilterDates] = useState<FilterDatesCommissioning>(dates);

  const [commissioningTasks, setCommissioningTasks] = useState<CommissioningTask[]>([]);
  const [activeTab, setActiveTab] = useState<CommissioningTaskTab>(currentTabIndex);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [isTaskAbortModalOpen, setIsTaskAbortModalOpen] = useState(false);
  const [abortTask, setAbortTask] = useState<CommissioningTask | undefined>();
  const [estimatedObstacles, setEstimatedObstacles] = useState<{
    [key: string]: IObstacleInformation1ST;
  }>({});
  const socket = webSocket();
  const { fetchCommissioningTask } = useFetchCommissioningTasks();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatchGroundControlLevel } = useGroundControlStore();
  const { requestController } = useRequestController('Commissioning');

  const { systemId = '', flightDomainId = '' } = useParams();

  const initCommissioningPage = useCallback(async () => {
    const flightDomainData: IFlightDomainData = initialFlightDomain;
    flightDomainData.flight_domain_id = flightDomainId;
    // set flight domain data
    dispatchGroundControlLevel({
      type: GroundControlActionNames.SET_FLIGHT_DOMAIN,
      payload: flightDomainData,
    });
    setIsSpinnerActive(true);
    const commissioningTasks = await fetchCommissioningTask(
      systemId,
      flightDomainId,
      filterDates,
      commissioningTaskStateFromTab(activeTab),
    );
    setCommissioningTasks(commissioningTasks);
    await getEstimatedObstacles({
      systemId,
      flightDomainId,
      requestController,
      onSuccess: (r) => setEstimatedObstacles(r.estimated_obstacles),
    });
    setIsSpinnerActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDates, location.pathname, systemId, activeTab]);

  useEffect(() => {
    subscribeToEstimatedObstacles({ socket, flightDomainId, onSubscribe: setEstimatedObstacles });

    return () =>
      socket.unsubscribeAll<CommissioningPageSubscribersOptions>(commissioningPageSubscribers);
  }, [socket, flightDomainId]);

  useEffect(() => {
    initCommissioningPage();
  }, [filterDates, initCommissioningPage]);

  const abortCommissioningTasksCallback = (task: CommissioningTask) => {
    setAbortTask(task);
    setIsTaskAbortModalOpen(true);
  };

  const rows: CommissioningTaskTableRow[] = transformTasksToTableRows(
    filterCommissioningTasks(
      commissioningTasks,
      filterDates,
      commissioningTaskStateFromTab(activeTab),
    ),
    systemId,
    abortCommissioningTasksCallback,
  );
  const onClose = () => {
    setIsTaskAbortModalOpen(false);
  };

  const onConfirm = () => {
    setIsTaskAbortModalOpen(false);
  };

  return (
    <>
      <PageLayout>
        <PageLayout.Header>
          <PageHeader
            systemId={systemId}
            title="Commissioning - Tasks"
            subtitle={
              <>
                <span>Commissioning tasks progress for:</span>

                <SelectTimeInterval
                  enableIntervalSelectors
                  defaultTimeInterval={TIMEFRAME_TO_FETCH_REPORTS_FROM}
                  getDataFrom={setFilterDates}
                />
              </>
            }
          />
        </PageLayout.Header>

        <PageLayout.Section>
          <CardGrid className="c-page-content">
            <EnhancedTable
              tableFor="Commissioning Tasks"
              tableTitle="Tasks"
              tableSubtitle="Monitor commissioning tasks"
              headCells={commissioningTableHeaders}
              rows={rows}
              tabs={tabDataCommissioning}
              changeTab={setActiveTab}
              startingTab={activeTab}
              showSearch={true}
              isLoading={isSpinnerActive}
              currentActiveSpinner={{ 'Commissioning Tasks': isSpinnerActive }}
            />

            <div id={wrapperId}>
              <MapContainer systemId={systemId} flightDomainId={flightDomainId}>
                <EstimatedObstaclesMap estimatedObstacles={estimatedObstacles} />
              </MapContainer>
            </div>
          </CardGrid>
        </PageLayout.Section>
      </PageLayout>

      {isTaskAbortModalOpen &&
        AbortCommissioningTasksModal(
          abortTask,
          systemId,
          onConfirm,
          onClose,
          initCommissioningPage,
          enqueueSnackbar,
        )}
    </>
  );
};
