import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import { webSocket } from 'shared/web-socket/web-socket';
import { mapSortDetailsZones } from 'shared/map/utils/map-sort-details-zones/mapSortDetailsZones.util';
import { NoFlyZone, NoFlyZonesDTO } from 'shared/map/model/no-fly-zone.model';
import { DroneZoneDetailsPanel } from 'shared/map/features/drone-zone-details-panel/drone-zone-details-panel';
import { Vec6 } from 'shared/map-container/MapContainer.model';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { useStyles } from '../drone-zones/DroneZonesPage.styles';
import { useRequestController } from '../../../hooks';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { FlowState } from '../drone-zones/reducer/zoneStatusInitialState';
import { subscribeToNoFlyZoneSocket } from '../drone-zones/webSockets/subscribeToNoFlyZoneSocket';
import { mapZoneIsSelectedAndIsEditing } from '../drone-zones/features/helpers/mapSelectedZone';
import { NoFlyZoneHeader } from '../drone-zones/features/NoFlyZoneHeader/NoFlyZoneHeader';
import { wrapperId } from '../drone-zones/features/Map/DroneZonesMap';
import { MapContainer } from '../../../shared/map-container/MapContainer';
import { DeleteNoFlyZoneModal } from '../drone-zones/features/DeleteNoFlyZoneModal/DeleteNoFlyZoneModal';
import { getNoFlyZones } from './api/getNoFlyZones.api';
import { useNoFlyZoneContext } from './reducer/NoFlyZonePageContext';
import { NoFlyZonesMap } from './features/NoFlyZonesMap';
import { NoFlyZoneControls } from './features/NoFlyZoneControls';

export const NoFlyZonesPage = () => {
  const { systemId = '', flightDomainId = '' } = useParams();
  const { classes, cx } = useStyles();
  const { mapState } = useMapStore();
  const { requestController } = useRequestController('DroneZones');
  const socket = webSocket();

  const { noFlyZonePageState, dispatchNoFlyZone: dispatchZoneStatus } = useNoFlyZoneContext();

  const {
    noFlyZones,
    detailsZones,
    flowState,
    currentlyEditingZone,
    currentlySelectedZone,
    currentlyDeletingZone,
  } = noFlyZonePageState;

  const facilityHeight = mapState.map?.box?.[5] ?? 0;
  const isDetailsPanelOpen = !!(detailsZones?.length && facilityHeight);
  const sortedDetailsZones = mapSortDetailsZones({ ...noFlyZones }, detailsZones);

  useComponentDidMount(() => {
    getNoFlyZones({
      requestController,
      onBeforeSend: () =>
        dispatchZoneStatus({ type: 'SET_FLOW_STATE', payload: FlowState.LOADING }),
      onSuccess: (payload) =>
        dispatchZoneStatus({
          type: 'SET_NO_FLY_ZONES',
          payload: payload.data.no_fly_zones,
        }),
      onFinally: () => dispatchZoneStatus({ type: 'SET_FLOW_STATE', payload: FlowState.LIST }),
      systemId,
      flightDomainId,
    });
  });

  useEffect(() => {
    subscribeToNoFlyZoneSocket(
      flightDomainId,
      (noFlyZonesDTO: NoFlyZonesDTO) =>
        dispatchZoneStatus({
          type: 'SET_NO_FLY_ZONES',
          payload: noFlyZonesDTO,
        }),
      socket,
    );

    return () => {
      socket.unsubscribe({
        channel: 'ground-control/no-fly-zone/status-update',
        delegate: 'NoFlyZones',
      });
    };
  }, [socket, flightDomainId, dispatchZoneStatus]);

  const isDeletionModalVisible: boolean = flowState === 'DELETE' && currentlyDeletingZone !== null;

  const mappedNoFlyZones = useMemo(
    () =>
      mapZoneIsSelectedAndIsEditing({
        zones: noFlyZones,
        selectedZoneId: currentlySelectedZone?.id,
        editingZoneId: currentlyEditingZone?.id,
      }),
    [noFlyZones, currentlySelectedZone?.id, currentlyEditingZone?.id],
  );

  const handleSelectNoFlyZone = (noFlyZone: NoFlyZone | null) => {
    const isCurrentlySelected = noFlyZone?.id === currentlySelectedZone?.id;
    const payload = isCurrentlySelected ? null : noFlyZone;

    dispatchZoneStatus({
      type: 'SELECT_SINGLE_NO_FLY_ZONE',
      payload,
    });
  };

  const handleSelectFromNoFlyZoneGroup = (noFlyZone: NoFlyZone) => {
    dispatchZoneStatus({
      type: 'SELECT_SINGLE_FROM_NO_FLY_ZONE_GROUP',
      payload: noFlyZone,
    });
  };

  return (
    <>
      <PageLayout>
        <PageLayout.Header>
          <NoFlyZoneHeader
            systemId={systemId}
            isCreateEnabled={flowState === FlowState.LIST}
            onCreateButtonClicked={() =>
              dispatchZoneStatus({ type: 'CREATION_OF_ZONE_DRAWING_START', payload: null })
            }
          />
        </PageLayout.Header>

        <PageLayout.Section fullWidth>
          <div
            className={cx(classes.mapWrapper, {
              [classes.mapWrapperCoexistence]: false,
            })}
          >
            <div id={wrapperId} className={classes.map}>
              <MapContainer systemId={systemId} flightDomainId={flightDomainId}>
                <NoFlyZonesMap noFlyZones={mappedNoFlyZones} onClick={handleSelectNoFlyZone} />
              </MapContainer>

              {isDetailsPanelOpen && (
                <div className={classes.details}>
                  <DroneZoneDetailsPanel
                    showDronesFlyingBanner
                    zones={sortedDetailsZones}
                    selectedZone={currentlySelectedZone}
                    facilityHeight={facilityHeight}
                    onClose={() => handleSelectNoFlyZone(null)}
                    onSelect={handleSelectFromNoFlyZoneGroup}
                  />
                </div>
              )}
            </div>

            <div className={classes.sidemenu}>
              <NoFlyZoneControls
                systemId={systemId}
                flightDomainId={flightDomainId}
                flowState={flowState}
                currentlyEditingZone={currentlyEditingZone}
                noFlyZones={mappedNoFlyZones}
                worldBox={mapState.map?.box as Vec6}
                onSelectNoFlyZone={handleSelectNoFlyZone}
              />
            </div>
          </div>
        </PageLayout.Section>
      </PageLayout>

      {isDeletionModalVisible && (
        <DeleteNoFlyZoneModal
          noFlyZone={currentlyDeletingZone as NoFlyZone}
          onClose={() => {
            dispatchZoneStatus({ type: 'CLOSE_NO_FLY_ZONE_DELETION_MODAL', payload: null });
          }}
          onConfirm={() => {
            dispatchZoneStatus({ type: 'CONFIRM_NO_FLY_ZONE_DELETION_MODAL', payload: null });
          }}
        />
      )}
    </>
  );
};
