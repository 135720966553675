import { AxiosResponse } from 'axios';
import { ISystemIDsGetResponseValueST } from '../../../codegen/system';
import { Facilities } from '../model/facility.model';

export const transformUserFacilitiesFromDTO: (
  userFacilitiesDTO: AxiosResponse<{
    [p: string]: ISystemIDsGetResponseValueST;
  }>,
) => Facilities = (userFacilitiesDTO) =>
  Object.entries(userFacilitiesDTO.data).map(([key, value]) => ({
    name: value?.facility,
    id: key,
  }));
