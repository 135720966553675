import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CLIENT_PAGES_URLS, WARNING_PAGES_URLS } from 'common/pages';
import { ClientPage } from '../pages/layouts/ClientPage';
import { NotFound } from '../pages/Error/NotFound';
import { FacilityRoutes } from './Facility/FacilityRoutes';
import { SelectFacility } from '../udb/select-facility/SelectFacility';

export const LoggedInRoutes = () => (
  <ClientPage>
    <Routes>
      <Route path="/" element={<Navigate to={CLIENT_PAGES_URLS.SELECT_FACILITY} />} />
      <Route path="select-facility" element={<SelectFacility />} />
      <Route path="/:systemId/*" element={<FacilityRoutes />} />
      <Route path={WARNING_PAGES_URLS.NOT_FOUND} element={<NotFound />} />
      <Route path="*" element={<Navigate to={WARNING_PAGES_URLS.NOT_FOUND} />} />
    </Routes>
  </ClientPage>
);
