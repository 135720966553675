import { DevCycleClient, initializeDevCycle } from '@devcycle/js-client-sdk';

interface FeatureFlag {
  init: (clientName: string) => Promise<void>;
  updateIdentity: (identity: Omit<UserIdentity, 'clientName'>) => Promise<void>;
  hasFeature: (name: string) => boolean;
}

type UserIdentity = {
  clientName: string;
  user?: {
    id: string;
    email: string;
  };
  facility?: {
    id: string;
    name: string | undefined;
  };
  flightDomain?: {
    id: string;
    name: string;
  };
};

let userIdentity: UserIdentity;
let client: DevCycleClient;
export const featureFlag = (): FeatureFlag => ({
  init: async (clientName: string) => {
    if (import.meta.env.APP_ENV !== 'dev') return;
    try {
      // Call initialize with the client key and a user object
      // await on the features to be loaded from our servers
      client = await initializeDevCycle(import.meta.env.VITE_DEV_CYCLE_KEY, {
        customData: {
          client_name: clientName,
        },
      }).onClientInitialized();
      userIdentity = { clientName };
      console.debug('Feature Flag Client initialised');
    } catch (ex) {
      console.debug(`Error initializing DevCycle: ${ex}`);
    }
  },
  hasFeature: (name: string) =>
    import.meta.env.APP_ENV === 'dev' ? client.variableValue(name, false) : false,
  updateIdentity: async (identity: Omit<UserIdentity, 'clientName'>) => {
    if (import.meta.env.APP_ENV !== 'dev') return;

    userIdentity = { ...userIdentity, ...identity };
    await client.identifyUser({
      user_id: userIdentity.user?.id,
      email: userIdentity.user?.email,
      customData: {
        facility_id: userIdentity.facility?.id ?? null,
        facility_name: userIdentity.facility?.name ?? null,
        flight_domain_id: userIdentity.flightDomain?.id ?? null,
        flight_domain_name: userIdentity.flightDomain?.name ?? null,
      },
    });

    console.debug('Feature Flag Identity updated: ', userIdentity);
  },
});

export const initFeatureFlag = async () => {
  if (import.meta.env.APP_ENV !== 'dev') return;
  const getSubdomain = (l: Location) => {
    const parts = l.hostname.split('.');
    return parts.length > 2 ? parts.slice(0, parts.length - 2).join('.') : null;
  };
  console.debug('The URL is: ', window.location.hostname);
  console.debug('The subdomain is: ', getSubdomain(window.location) ?? 'localhost');

  const clientName: string = getSubdomain(window.location) ?? 'localhost';
  await featureFlag()
    .init(clientName)
    .catch((e) => console.error(e));
};
